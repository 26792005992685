import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Kiri on saadetud</h2>
          <p>Aitäh. Vastame esimesel võimalusel</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <Link
            to="/"
          >
            Tagasi pealehele
          </Link>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
